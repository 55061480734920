import React, {useState, useEffect} from 'react';
import { useLanguage } from '../../../Translations/LenguageContext';
import AddContactModal from './AddContactModal';
import DeleteContactModal from './DeleteContactModal';
import PopupMex from '../SavePopup';
import { Dropdown } from 'react-bootstrap';

function Contacts() {
    const { t } = useLanguage();
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [popupActive, setPopupActive] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [popupType, setPopupType] = useState("success");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedContactKey, setSelectedContactKey] = useState(null);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));
    const [selectedContactName, setSelectedContactName] = useState('');
    const [selectedContactSurname, setSelectedContactSurname] = useState('');
    const [selectedContact, setSelectedContact] = useState("");

    const handleClose = () => {
        setSelectedContact(null);
        setShowModal(false);
    };
    const handleShow = (selectedContact) => {
        setSelectedContact(selectedContact);
        setShowModal(true);
    };

    const fetchContacts = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const contactsArray = Object.entries(data.contacts || {}).map(([key, value]) => ({
                key,
                ...value
            }));
            setContacts(contactsArray);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContacts();
    }, [userId]);

    const handleContactAdded = () => {
        setPopupType("success");
        setPopupActive(true);
        fetchContacts();
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleDeleteClick = (key, contact_name, contact_surname) => {
        setSelectedContactKey(key);
        setShowDeleteModal(true);
        setSelectedContactName(contact_name);
        setSelectedContactSurname(contact_surname);
    };

    const handleEditClick = (key, contact_user) => {
        setShowModal(true)
        setSelectedContactKey(key)
        setSelectedContact(contact_user)
    }

    const handleDeleteConfirmed = async () => {
        setShowDeleteModal(false);
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, contact_key: selectedContactKey }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete contact');
            }

            setContacts(contacts.filter(contact => contact.key !== selectedContactKey));

            setPopupType("success");
            setPopupActive(true);
        } catch (error) {
            setPopupType("error");
            setPopupActive(true);
            console.error(error);
        }
    };

    const filteredContacts = contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchQuery) ||
        contact.email.toLowerCase().includes(searchQuery) ||
        contact.phone.toLowerCase().includes(searchQuery)
    );

    if (loading) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.contacts}</p>
            <div className='property_bar_table_cont'>
                <div className='property_func_bar'>
                    <button className='add_property_btn' onClick={() => handleShow(null)}>
                        <i className='fa-solid fa-plus'></i>{t.contacts.add_contact}
                    </button>

                    <div className='filter_input_cont'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                        <input
                            className='search_bar_input'
                            placeholder={`${t.tenants.search_contacts}...`}
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                <div className='contacts_content'>
                    <div className='contacts_table_cont'>
                        <div className='contacts_table_header'>
                            <div className='contacts_header'>
                                <div className='header_cell_first'></div>
                                <div className='header_cell'>
                                    <p className='header_cell_label'>Nome</p>
                                </div>
                                <div className='header_cell'>
                                    <p className='header_cell_label'>Cognome</p>
                                </div>
                                <div className='header_cell'>
                                    <p className='header_cell_label'>Telefono</p>
                                </div>
                                <div className='header_cell'>
                                    <p className='header_cell_label'>Email</p>
                                </div>
                                <div className='header_cell'>
                                    <p className='header_cell_label'>Note</p>
                                </div>
                            </div>
                        </div>
                        <div className='contacts_table_body'>
                            {filteredContacts.length > 0 ? (
                                filteredContacts.map((contact) => (
                                    <div key={contact.key} className='contacts_record'>
                                        <div className='table_td_first'>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className='custom-dropdown-toggle'
                                                    as="div"
                                                >
                                                    <i className='fa-solid fa-ellipsis-vertical'></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='custom-dropdown-menu'>
                                                    <Dropdown.Item
                                                        className='drop_down_item'
                                                        onClick={() => handleEditClick(contact.key, contact)}
                                                    >
                                                        <i className='fa-solid fa-pencil'></i> {t.edit}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className='drop_down_item delete'
                                                        onClick={() => handleDeleteClick(contact.key, contact.name, contact.surname)}
                                                    >
                                                        <i className='fa-solid fa-trash'></i> {t.delete}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className='body_cell'>
                                            <p>{contact.name}</p>
                                        </div>
                                        <div className='body_cell'>
                                            <p>{contact.surname}</p>
                                        </div>
                                        <div className='body_cell'>
                                            <p>{contact.phone}</p>
                                        </div>
                                        <div className='body_cell'>
                                            <p>{contact.email}</p>
                                        </div>
                                        <div className='body_cell'>
                                            <p>{contact.notes}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='no_data_mex_cont'>
                                    <p className='no_data_mex'>{t.contacts.no_contacts}</p>
                                    <div className='no_data_img'></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <AddContactModal
                onContactAdded={handleContactAdded}
                show={showModal}
                handleClose={handleClose}
                contact={selectedContact}
            />
            <DeleteContactModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                onDeleteConfirmed={handleDeleteConfirmed}
                contactName={selectedContactName}
                contactSurname={selectedContactSurname}
            />

            <PopupMex active={popupActive} type={popupType} onClose={handlePopupClose} />
        </div>
    );
}

export default Contacts;
