import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiHomeAlt2, BiCalendar, BiUser, BiSolidReport } from "react-icons/bi";
import { BsHouses, BsPeople, BsGear } from "react-icons/bs";
import { TbTransactionEuro } from "react-icons/tb";
import { LiaBalanceScaleLeftSolid } from "react-icons/lia";
import { CiBoxes } from "react-icons/ci";
import { IoMdBusiness } from "react-icons/io";
import { PiHandCoins } from "react-icons/pi";
import { FiMessageSquare } from "react-icons/fi";
import { RiTranslate2 } from "react-icons/ri";
import { BiSolidFlask } from "react-icons/bi";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CgWebsite } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { RiSecurePaymentFill } from "react-icons/ri";
import { FaBuildingUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function LLSidebar({ onNavigate, userData }) {
    const { t, changeLanguage, language } = useLanguage();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const pathName = location.pathname.split('/').pop();
        setActiveLink(pathName);
    }, [location.pathname]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onNavigate(link);
    };

    const getLinkStyle = (link) => {
        return activeLink === link ? { border: '1px solid var(--lightGrey)', color: "var(--mainColor)", backgroundColor: "var(--lightGrey)" } : {};
    };

    const renderTooltip = (props) => (
        <Tooltip id="coming_soon_tooltip" {...props}>
            <p>{t.sidebar.coming_soon_mex}</p>
        </Tooltip>
    );

    return (
        <div className="sidebar_cont">
            <div className="sidebar_header">
                <p className="sidebar_logo"><i className="fa-solid fa-layer-group"></i> Paypermate</p>
            </div>
            <div className="sidebar_body">
                <div className="link_container">
                    <p className="side_link" onClick={() => handleLinkClick('home')} style={getLinkStyle('home')}><i><BiHomeAlt2 /></i>{t.sidebar.home}</p>
                    <p className="side_link" onClick={() => handleLinkClick('bookings')} style={getLinkStyle('bookings')}><i><BiCalendar /></i>{t.sidebar.bookings}</p>
                    <p className="side_link" onClick={() => handleLinkClick('properties')} style={getLinkStyle('properties')}><i><BsHouses /></i>{t.sidebar.properties}</p>
                    <p className="side_link" onClick={() => handleLinkClick('tenants')} style={getLinkStyle('tenants')}><i><FaBuildingUser /></i>{t.tenants.tenants}</p>
                    <p className="side_link" onClick={() => handleLinkClick('contacts')} style={getLinkStyle('contacts')}><i><BsPeople /></i>{t.sidebar.contacts}</p>
                    <p className="side_link" onClick={() => handleLinkClick('messages')} style={getLinkStyle('messages')}><i><FiMessageSquare /></i>{t.sidebar.messages}</p>
                    {/* <p className="side_link soon_available" onClick={() => handleLinkClick('email')}><i><MdEmail /></i> <span className="coming_soon_text">{t.sidebar.website_builder}</span>   <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p> */}
                    <p className="side_link soon_available" onClick={() => handleLinkClick('website-builder')}><i><CgWebsite /></i> <span className="coming_soon_text">{t.sidebar.website_builder}</span>   <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                </div>
                <p className="link_title">Finance</p>
                <div className="link_container">
                    <p className="side_link" onClick={() => handleLinkClick('owner-payments')} style={getLinkStyle('owner-payments')}><i><RiSecurePaymentFill /></i>{t.sidebar.payments} </p>
                    <p className="side_link soon_available"><i><TbTransactionEuro /></i> <span className="coming_soon_text">{t.sidebar.transactions}</span>    <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                    <p className="side_link soon_available"><i><LiaBalanceScaleLeftSolid /></i> <span className="coming_soon_text">{t.sidebar.balance}</span>    <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                    <p className="side_link soon_available"><i><BiSolidReport /></i> <span className="coming_soon_text">{t.sidebar.report}</span>    <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                </div>
                <p className="link_title"><span className="ia_span">AI</span> Analytics</p>
                <div className="link_container">
                    <p className="side_link soon_available small"><i><CiBoxes /></i><span className="coming_soon_text">{t.sidebar.tenant_analysis}</span> <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                    <p className="side_link soon_available small"><i><IoMdBusiness /></i><span className="coming_soon_text">{t.sidebar.market_pricing}</span> <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                    <p className="side_link soon_available small"><i><PiHandCoins /></i><span className="coming_soon_text">{t.sidebar.expenses_forecast}</span> <OverlayTrigger placement="top" overlay={renderTooltip}><span className="soon_available_mex"><BiSolidFlask /></span></OverlayTrigger></p>
                </div>
            </div>
            <div className="sidebar_footer">
                <div className="link_container">
                    <div className="profile_cont_link">
                        <i><RiTranslate2 /></i>
                        <select className="language_select_sidebar" onChange={handleLanguageChange} value={language}>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                            {/* <option value="de">Deutsch</option> */}
                            {/* <option value="fr">Francaise</option> */}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LLSidebar;
