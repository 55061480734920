import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";
import { BiBell } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { Dropdown } from 'react-bootstrap';
import { HiLogout } from "react-icons/hi";
import Spinner from "../components/Spinner";
import { SwipeableDrawer } from "@mui/material";
import Switch from "./SwitchTestMode";

function NavBar({ onNavigate, userData }) {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); 
    const [isSwitchOn, setIsSwitchOn] = useState(false); // State for the switch


    useEffect(() => {
        if (userData) {
            setLoading(false);
        }
    }, [userData]);

    const handleLogout = () => {
        setLoading(true)
        sessionStorage.removeItem("user_id");
        setTimeout(() => {
            navigate(`/login`);
        }, 700);
    };

    const toggleSwitch = () => {
        setIsSwitchOn(!isSwitchOn); // Toggle the switch state
    };

    return (
        <div className="control_navbar">
            <div className="control_nav_cont">
            </div>
            <div className="control_nav_cont">
                <div className="icon_cont_nav">
                    <Dropdown>
                        <Dropdown.Toggle className="bell">
                            <BsGear />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu-settings">
                            <div className="settings_center">
                                <p className="settings_link" onClick={() => onNavigate('settings')}><i><BsGear /></i> {t.settings.settings_title}</p>
                                <p className="settings_link" onClick={() => onNavigate('profile')}><i><BiUser /></i> {t.profile.profile_title}</p>
                                <div className="settings_link last_link" onClick={handleLogout}>
                                    <p><i><HiLogout /></i> Logout</p>
                                </div>
                                {loading ? (
                                    <div className="settings_link_info">
                                        <Spinner loading={true} err={({message: "", display: "none"})} />
                                    </div>
                                ) : (
                                    userData && (
                                        <div className="settings_link_info" onClick={() => onNavigate('profile')}>
                                            <p className="logout_from_account">{userData.name} {userData.surname}</p>
                                            <p className="logout_from_account">
                                                {userData.user_type.charAt(0).toUpperCase() + userData.user_type.slice(1)}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="bell">
                            <BiBell />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu-notification">
                            <div className="notifications_center">
                                <p className="notifications_title">Notifiche</p>
                                <p href="#/action-1" className="notification">Notification 1</p>
                                <p href="#/action-1" className="notification">Notification 1</p>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* <Switch isOn={isSwitchOn} handleToggle={toggleSwitch} /> */}
                </div>
            </div>
        </div>
    );
}

export default NavBar;
