import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { FaHandsHelping } from "react-icons/fa";
import { FaPersonCirclePlus } from "react-icons/fa6";
import Spinner from "../../../../components/Spinner";

const TenantAccount = ({ tenant, new_update, tenantId, toggleComponent, isActive }) => {
    const { t } = useLanguage();

    const [tenantType, setTenantType] = useState("person");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [userId] = useState(sessionStorage.getItem("user_id"));
    const [check, setCheck] = useState(false);

    const handleCreateAccount = async () => {
        setLoading(true); 

        fetch("http://127.0.0.1:5000/create_tenant_account_request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user_id: userId, tenant_id: tenant })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setLoading(false);
                setErr({ message: "", display: false });
                const tenantData = data.tenant?.personal_information || {};
            
            
            })
            .catch(error => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };

    return (
        <div className={`tenant_label_input_cont ${check ? "label_input_cont_done" : ""}`}>
            <div className={`new_tenant_title_chevron_cont ${isActive ? "new_tenant_title_chevron_cont_open" : ""}`} onClick={() => toggleComponent("tenantAccount")}>
                <div className="new_tenant_title_desc_cont">
                    <p className="new_tenant_title">{t.tenants.tenant_account_title}</p>
                    <p className="description_tenant_title">{t.tenants.tenant_account_desc}</p>
                </div>
                <div className="new_tenant_check_chevron_cont">
                    <i className={`fa-regular ${check ? "fa-circle-check check_tenant" : "fa-circle circle_tenant"}`}></i>
                    <i className={`fa-solid ${isActive ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                </div>
            </div>
            <div className={`toggle_tenant_info_cont ${isActive ? "active_tenant_info" : ""}`}>
                <div className="tenant_account_setup_container">
                    <div className="tenant_setup_cont">
                        <p className="tenant_setup_account_title">
                            <i><FaPersonCirclePlus /></i> Invia link per la creazione dell'account
                        </p>
                        <p className="tenant_setup_account_subtitle">
                            L'inquilino riceverà un'email con le istruzioni per l'accesso. Una volta effettuato l'accesso, potrà accettare e gestire i pagamenti, inserire dati e fornire i documenti da te richiesti.
                        </p>
                        <button className="create_tenant_account_btn"
                            onClick={handleCreateAccount}
                            disabled={loading}>
                            <Spinner loading={loading} err={err} />
                            <span>INVIA LINK</span>
                        </button>
                    </div>
                    <div className="tenant_setup_cont">
                        <p className="tenant_setup_account_title">
                            <i><FaHandsHelping /></i> Se l'inquilino ha già un account Paypermate
                        </p>
                        <p className="tenant_setup_account_subtitle">
                            Inserisci il PayperCode se l'inquilino possiede già un account Paypermate.
                        </p>
                        <div className="tenant_account_input_btn">
                            <input placeholder="123a1ded8ad6ced8ad6c..." className="tenant_id_input"></input>
                            <button className="create_tenant_account_btn"><i className="fa-solid fa-check"></i></button>
                        </div>
                    </div>
                </div>
                {err.display && <p className="error_message">{err.message}</p>}
            </div>
        </div>
    );
};

export default TenantAccount;
