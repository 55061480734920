import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";

const WaitlistConfirm = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const [showTextAndRocket, setShowTextAndRocket] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTextAndRocket(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handlePartnership = () => {
        setShowTextAndRocket(false);
        setTimeout(() => {
            navigate("/check-email")
        }, 1000);
    };

    const handleDecline = () => {
        setTimeout(() => {
            navigate("/")
        }, 1000);
    }

    return (
        <div className="waitlist_confirm_main_cont">
            <div className="suprise_waitlist">
                <div className={`suprise_waitlist_cont ${showTextAndRocket ? 'show_text' : ''}`}>
                    <p className="explanation_waitlist_title">{t.waitlist_partner.free_title}</p>
                    <p className="explanation_waitlist_subtitle">{t.waitlist_partner.free_subtitle}</p>

                    <div className="explanation_waitlist">
                        <p>{t.waitlist_partner.explain_1}</p>
                        <p>{t.waitlist_partner.explain_2}</p>

                        <div className="waitlist_confirm_btn_cont">
                            <button className="waitin_list_btn_start" onClick={handlePartnership}>
                                <Spinner loading={false} err={{ message: "", display: false }} />
                                <span>{t.waitlist_partner.become_partner_btn}</span>
                            </button>
                            <button className="waitin_list_btn_no" onClick={handleDecline}>{t.waitlist_partner.no_btn}</button>
                        </div>
                    </div>
                </div>

                <div className={`suprise_waitlist_cont ${showTextAndRocket ? 'waitlist_animation' : ''}`}>
                    <div className={`rocket_img ${showTextAndRocket ? 'rocket_down' : 'rocket_up'}`}></div>
                </div>
            </div>
        </div>
    );
};

export default WaitlistConfirm;
