import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiHomeAlt2, BiCalendar, BiUser, BiSolidReport } from "react-icons/bi";
import { BsHouses, BsPeople, BsGear } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";
import { RiTranslate2 } from "react-icons/ri";
import { BiSolidFlask } from "react-icons/bi";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CgWebsite } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { RiSecurePaymentFill } from "react-icons/ri";


function TenantSidebar({ onNavigate, userData }) {
    const { t, changeLanguage, language } = useLanguage();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const pathName = location.pathname.split('/').pop();
        setActiveLink(pathName);
    }, [location.pathname]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onNavigate(link);
    };

    const getLinkStyle = (link) => {
        return activeLink === link ? { border: '2px solid var(--mainColor)', color: "var(--mainColor)", backgroundColor: "rgb(86, 89, 255, 0.2)" } : {};
    };

    const renderTooltip = (props) => (
        <Tooltip id="coming_soon_tooltip" {...props}>
            <p>{t.sidebar.coming_soon_mex}</p>
        </Tooltip>
    );

    return (
        <div className="sidebar_cont">
            <div className="sidebar_header">
                <p className="sidebar_logo"><i className="fa-solid fa-layer-group"></i> Paypermate</p>
            </div>
            <div className="sidebar_body">

                <div className="link_container">
                    <p className="side_link" onClick={() => handleLinkClick('home')} style={getLinkStyle('home')}><i><BiHomeAlt2 /></i>{t.sidebar.home}</p>
                    <p className="side_link" onClick={() => handleLinkClick('tenant-payment')} style={getLinkStyle('tenant-payment')}><i><RiSecurePaymentFill /></i>{t.sidebar.payments}</p>
                    <p className="side_link" onClick={() => handleLinkClick('messages')} style={getLinkStyle('messages')}><i><FiMessageSquare /></i>{t.sidebar.messages}</p>
                </div>

                <div className="link_container">
                    <div className="tenant_info">
                        <p className="tenant_title">{userData.name} {userData.surname}</p>
                        <p className="tenant_parag">{userData.email}</p>
                    </div>
                </div>

            </div>
            <div className="sidebar_footer">
                <div className="link_container">
                    <div className="profile_cont_link">
                        <i><RiTranslate2 /></i>
                        <select className="language_select_sidebar" onChange={handleLanguageChange} value={language}>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                            {/* <option value="de">Deutsch</option> */}
                            {/* <option value="fr">Francaise</option> */}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantSidebar;
