import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";

const PersonalizedPayments = ({ selectedProperty, onPersonalizedPaymentsUpdate }) => {
    const { t } = useLanguage();
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [paymentType, setPaymentType] = useState("selected_period");
    const [monthlyPayments, setMonthlyPayments] = useState("");
    const [currency, setCurrency] = useState("");
    const [commissionCost, setCommissionCost] = useState(0);
    const [isDepositIncluded, setIsDepositIncluded] = useState(false);
    const [isCommissionIncluded, setIsCommissionIncluded] = useState(false);

    useEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const numDays = new Date(year, month + 1, 0).getDate();

        const firstDay = new Date(year, month, 1);
        const firstDayIndex = (firstDay.getDay() + 6) % 7;

        const days = Array.from({ length: firstDayIndex }, () => null).concat(
            Array.from({ length: numDays }, (_, i) => new Date(year, month, i + 1))
        );

        setDaysInMonth(days);
    }, [currentDate]);

    useEffect(() => {
        if (selectedProperty) {
            const monthlyAmountPrice = selectedProperty.price_property || 0;
            const monthlyAmountExpenses = selectedProperty.expenses_property || 0;
            const monthlyAmountDeposit = selectedProperty.deposit_property || 0;
            const monthlyAmountCommission = selectedProperty.commissions_property || 0;
            const monthlyAmountCommissionType = selectedProperty.commission_type_property || "";
            const monthlyAmountCurrency = selectedProperty.currency_property || "";

            let calculatedCommissionCost;
            if (["€", "$", "£"].includes(monthlyAmountCommissionType)) {
                calculatedCommissionCost = Number(monthlyAmountCommission);
            } else {
                calculatedCommissionCost =
                    (Number(monthlyAmountPrice) * Number(monthlyAmountCommission)) / 100;
            }

            setCommissionCost(calculatedCommissionCost);
            setCurrency(monthlyAmountCurrency);

            const initialMonthlyPayment = Number(monthlyAmountPrice) + Number(monthlyAmountExpenses);
            setMonthlyPayments(initialMonthlyPayment || "");
        } else {
            setMonthlyPayments("");
            setCurrency("");
            setCommissionCost(0);
        }
    }, [selectedProperty]);

    useEffect(() => {
        let newMonthlyPayments = selectedProperty ? (Number(selectedProperty.price_property) + Number(selectedProperty.expenses_property)) : 0;

        if (isDepositIncluded && selectedProperty) {
            newMonthlyPayments += Number(selectedProperty.deposit_property);
        }

        if (isCommissionIncluded && selectedProperty) {
            newMonthlyPayments += commissionCost;
        }

        setMonthlyPayments(newMonthlyPayments || "");

        onPersonalizedPaymentsUpdate({
            monthlyPayments: newMonthlyPayments,
            currency: currency,
            isDepositIncluded,
            isCommissionIncluded,
            selectedStartDate: formatDate(selectedStartDate),
            selectedEndDate: formatDate(selectedEndDate)
        });
    }, [isDepositIncluded, isCommissionIncluded, commissionCost, selectedProperty, selectedStartDate, selectedEndDate, onPersonalizedPaymentsUpdate]);


    const handleDateClick = (date) => {
        if (!selectedStartDate || (selectedEndDate && date < selectedStartDate)) {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        } else if (!selectedEndDate) {
            if (date < selectedStartDate) {
                setSelectedStartDate(date);
                setSelectedEndDate(null);
            } else {
                setSelectedEndDate(date);
            }
        } else {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        }
    };

    const isInRange = (date) => {
        return selectedStartDate && selectedEndDate && date >= selectedStartDate && date <= selectedEndDate;
    };

    const isStartDate = (date) => {
        return selectedStartDate && date && date.toDateString() === selectedStartDate.toDateString();
    };

    const isEndDate = (date) => {
        return selectedEndDate && date && date.toDateString() === selectedEndDate.toDateString();
    };

    const isStartOfWeek = (date) => {
        return date && date.getDay() === 1;
    };

    const isEndOfWeek = (date) => {
        return date && date.getDay() === 0;
    };

    const changeMonth = (direction) => {
        setCurrentDate((prevDate) => {
            const newMonth = prevDate.getMonth() + direction;
            return new Date(prevDate.getFullYear(), newMonth, 1);
        });
    };

    const formatDate = (date) => {
        if (!date) return "";

        const adjustedDate = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60 * 1000));
        return adjustedDate.toISOString().split("T")[0];
    };

    const weekdayInitials = t.booking.date_leng === 'it-IT' ? ["L", "M", "M", "G", "V", "S", "D"] : ["M", "T", "W", "T", "F", "S", "S"];

    const getDayClassName = (date) => {
        if (!date) return "calendar_day";

        let className = "calendar_day";

        if (isInRange(date)) {
            className += " selected";
        }
        if (isStartDate(date)) {
            className += " start-date";
        }
        if (isEndDate(date)) {
            className += " end-date";
        }

        if (paymentType === "daily" && isInRange(date)) {
            className += " daily-radius";
        }

        if (paymentType === "weekly") {
            if (isInRange(date) && isStartOfWeek(date)) {
                className += " start-of-week";
            }
            if (isInRange(date) && isEndOfWeek(date)) {
                className += " end-of-week";
            }
        }

        return className;
    };

    const handleInputChange = (value, isStart) => {
        if (isStart !== undefined) {
            // Handle date input
            const date = new Date(value);
            if (isStart) {
                setSelectedStartDate(date);
                if (selectedEndDate && date > selectedEndDate) {
                    setSelectedEndDate(null); // Reset end date if it's earlier than start date
                }
            } else {
                setSelectedEndDate(date);
            }
        } else {
            // Handle monthly payment input
            const numericValue = parseFloat(value);
            setMonthlyPayments(isNaN(numericValue) ? "" : numericValue); // Ensure numeric value
        }
    };

    // Update monthly payments input change handler
    <input
        className="period_payment_input"
        type="number"
        value={monthlyPayments || ""}
        onChange={(e) => handleInputChange(e.target.value)} // Pass the value directly
    />


    const handleCheckboxChange = (event, groupName) => {
        const checkboxes = document.querySelectorAll(`input[name="${groupName}"]`);
        checkboxes.forEach(checkbox => {
            if (checkbox !== event.target) {
                checkbox.checked = false;
            }
        });

        if (groupName === 'deposito') {
            setIsDepositIncluded(event.target.value === "yes");
        }

        if (groupName === 'commissioni') {
            setIsCommissionIncluded(event.target.value === "yes");
        }
    };

    useEffect(() => {
        let newMonthlyPayments = selectedProperty ? (Number(selectedProperty.price_property) + Number(selectedProperty.expenses_property)) : 0;

        if (isDepositIncluded && selectedProperty) {
            newMonthlyPayments += Number(selectedProperty.deposit_property);
        }

        if (isCommissionIncluded && selectedProperty) {
            newMonthlyPayments += commissionCost;
        }

        setMonthlyPayments(newMonthlyPayments || "");
    }, [isDepositIncluded, isCommissionIncluded, commissionCost, selectedProperty]);

    return (
        <div className="rent_ammount_choise">
            <p className="payment_title">{t.tenants.personalized_payments_title}</p>
            <p className="payment_description">{t.tenants.personalized_payments_desc}</p>

            <div className="from_to_container">
                <div className="from_to_payment">
                    <label>{t.tenants.from}</label>
                    <input
                        type="date"
                        value={formatDate(selectedStartDate) || ""}
                        onChange={(e) => handleInputChange(e, true)} // Handle start date change
                    />
                </div>
                <div className="from_to_payment">
                    <label>{t.tenants.to}</label>
                    <input
                        type="date"
                        value={formatDate(selectedEndDate) || ""}
                        onChange={(e) => handleInputChange(e, false)} // Handle end date change
                    />
                </div>
            </div>
            <div className="month-navigation">
                <button onClick={() => changeMonth(-1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <span>{currentDate.toLocaleString(t.booking.date_leng, { month: "long", year: "numeric" })}</span>
                <button onClick={() => changeMonth(1)}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
            <div className="mini_calendar">
                {weekdayInitials.map((initial, index) => (
                    <div key={index} className="calendar_weekday">
                        {initial}
                    </div>
                ))}
                {daysInMonth.map((date, index) => (
                    <div
                        key={index}
                        className={getDayClassName(date)}
                        onClick={() => date && handleDateClick(date)}
                    >
                        {date ? date.getDate() : ""}
                    </div>
                ))}
            </div>

            <div className="det_payment_personalized_cont">
                <label className="label_fraction_payment">{t.tenants.payment_fractioning}</label>
                <select
                    className="fraction_payment"
                    onChange={(e) => setPaymentType(e.target.value)}
                    value={paymentType}
                >
                    <option value={"selected_period"}>{t.tenants.selected_period}</option>
                    <option value={"daily"}>{t.tenants.daily}</option>
                    <option value={"weekly"}>{t.tenants.weekly}</option>
                </select>
            </div>
            <div className="period_payment">
                <label className="period_payment_label">{t.tenants.total} {t.tenants[paymentType]}</label>
                <input
                    className="period_payment_input"
                    type="number"
                    value={monthlyPayments || ""}
                    onChange={(e) => handleInputChange(e.target.value)} // Pass the value directly
                />
                <p className="currency_period_payment">{currency}</p>
            </div>
            <div className="period_payment">
                <label className="period_payment_label">Includi Deposito</label>
                <div className="checkbox_label_cont">
                    <div className="checkbox_label">
                        <label>Si</label>
                        <input
                            type="checkbox"
                            name="deposito"
                            value="yes"
                            checked={isDepositIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'deposito')}
                        />
                    </div>
                    <div className="checkbox_label">
                        <label>No</label>
                        <input
                            type="checkbox"
                            name="deposito"
                            value="no"
                            checked={!isDepositIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'deposito')}
                        />
                    </div>
                </div>
            </div>

            <div className="period_payment">
                <label className="period_payment_label">Includi commissioni</label>
                <div className="checkbox_label_cont">
                    <div className="checkbox_label">
                        <label>Si</label>
                        <input
                            type="checkbox"
                            name="commissioni"
                            value="yes"
                            checked={isCommissionIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'commissioni')}
                        />
                    </div>
                    <div className="checkbox_label">
                        <label>No</label>
                        <input
                            type="checkbox"
                            name="commissioni"
                            value="no"
                            checked={!isCommissionIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'commissioni')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalizedPayments;
