import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import nationData from "../../../../Geo/SupportedStripeCountries.json";

const PersonalInformationTenantForm = ({
  formData,
  handleChange,
  errorFields,
}) => {
  const { t } = useLanguage();
  const [cities, setCities] = useState([]);

  // useEffect(() => {
  //   if (formData.country && nationData[formData.country]) {
  //     setCities(nationData[formData.country]);
  //   } else {
  //     setCities([]);
  //   }
  // }, [formData.country]);

  // const handleCountryChange = (e) => {
  //   handleChange(e);
  //   const selectedCountry = e.target.value;
  //   if (nationData[selectedCountry]) {
  //     setCities(nationData[selectedCountry]);
  //   } else {
  //     setCities([]);
  //   }
  // };

  return (
    <div className="tenant_info_cont">
      <div className="tenant_info_single_cont">
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.register_form.name}</label>
          <input
            id="name"
            name="name"
            className={`tenant_input ${errorFields.name ? "error_border" : ""}`}
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.register_form.surname}</label>
          <input
            id="surname"
            name="surname"
            className={`tenant_input ${errorFields.surname ? "error_border" : ""
              }`}
            value={formData.surname}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.birthday}</label>
          <input
            id="birthday"
            name="birthday"
            type="date"
            className={`tenant_input ${errorFields.birthday ? "error_border" : ""
              }`}
            value={formData.birthday}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.sex}</label>
          <select
            id="sex"
            name="sex"
            className={`tenant_input ${errorFields.sex ? "error_border" : ""}`}
            value={formData.sex}
            onChange={handleChange}
          >
            <option value="">-</option>
            <option value="M">M</option>
            <option value="F">F</option>
          </select>
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.country}</label>
          <select
            id="country"
            name="country"
            className={`tenant_input ${errorFields.country ? "error_border" : ""
              }`}
            value={formData.country}
            onChange={handleChange}
          // onChange={handleCountryChange}
          >
            <option value="">-</option>
            {nationData.map((nation) => (
              <option key={nation.code} value={nation.code}>
                {nation.name}
              </option>
            ))}
          </select>
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.city}</label>
          <input
            id="city"
            name="city"
            className={`tenant_input ${errorFields.city ? "error_border" : ""}`}
            value={formData.city}
            onChange={handleChange}
          ></input>
          {/* <select
            id="city"
            name="city"
            className={`tenant_input ${errorFields.city ? "error_border" : ""}`}
            value={formData.city}
            onChange={handleChange}
          >
            <option value="">-</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select> */}
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.province}</label>
          <input
            id="province"
            name="province"
            className={`tenant_input ${errorFields.province ? "error_border" : ""
              }`}
            value={formData.province}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.street}</label>
          <input
            id="street"
            name="street"
            className={`tenant_input ${errorFields.street ? "error_border" : ""
              }`}
            value={formData.street}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.n}</label>
          <input
            id="n"
            name="n"
            className={`tenant_input ${errorFields.n ? "error_border" : ""}`}
            value={formData.n}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.zip}</label>
          <input
            id="zip"
            name="zip"
            className={`tenant_input ${errorFields.zip ? "error_border" : ""}`}
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="tenant_info_single_cont">
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.register_form.phone}</label>
          <select
            className={`tel_select_prefix ${errorFields.phone_prefix ? "error_border" : ""
              }`}
            value={formData.phone_prefix}
            onChange={handleChange}
            id="phone_prefix"
            name="phone_prefix"
          >
            <option value={""}>-</option>
            <option value={"123"}>+ 123</option>
          </select>
          <input
            id="phone"
            name="phone"
            type="phone"
            className={`tenant_input_phone ${errorFields.phone ? "error_border" : ""
              }`}
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">Email</label>
          <input
            id="email"
            name="email"
            className={`tenant_input ${errorFields.email ? "error_border" : ""
              }`}
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="tenant_lab_in_cont">
          <label className="tenant_label garant_label">
            {t.tenants.guarantor}
          </label>
          <select
            id="guarantor"
            name="guarantor"
            className={`tenant_input ${errorFields.guarantor ? "error_border" : ""
              }`}
            value={formData.guarantor}
            onChange={handleChange}
          >
            <option value={""}>-</option>
            <option value={"yes"}>{t.yes}</option>
            <option value={"no"}>{t.no}</option>
          </select>
        </div>

        <div className="tenant_lab_in_cont">
          <label className="tenant_label garant_label">Codice Fiscale</label>
          <input
            id="cf"
            name="cf"
            className={`tenant_input ${errorFields.cf ? "error_border" : ""}`}
            value={formData.cf}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformationTenantForm;
