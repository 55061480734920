import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";
import Spinner from "../components/Spinner";

const WaitingListForm = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState("");
    const [selectedProblems, setSelectedProblems] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [errors, setErrors] = useState({
        userType: false,
        name: false,
        email: false,
        privacy: false,
    });

    const goToHome = () => {
        navigate("/");
    };

    // User types with their translation keys
    const userTypes = [
        { title: t.waitlist.prop_owner, key: "prop_owner", bgImage: "url('IMG/roof_red_house.jpg')" },
        { title: t.waitlist.re_agent, key: "re_agent", bgImage: "url('IMG/re_broker.jpg')" },
        { title: t.waitlist.tenant, key: "tenant", bgImage: "url('IMG/boxes2.jpg')" },
        { title: t.waitlist.other, key: "other", bgImage: "url('IMG/question_mark.jpg')" },
    ];

    // Map user types to their problems using their keys
    const issuesByUserType = {
        [t.waitlist.tenant]: [
            t.waitlist.ten_1,
            t.waitlist.ten_2,
            t.waitlist.ten_3,
            t.waitlist.ten_4,
            t.waitlist.ten_5,
        ],
        [t.waitlist.prop_owner]: [
            t.waitlist.owner_1,
            t.waitlist.owner_2,
            t.waitlist.owner_3,
            t.waitlist.owner_4,
            t.waitlist.owner_5,
            t.waitlist.owner_6,
        ],
        [t.waitlist.re_agent]: [
            t.waitlist.re_agent_1,
            t.waitlist.re_agent_2,
            t.waitlist.re_agent_3,
            t.waitlist.re_agent_4,
        ],
        [t.waitlist.other]: [
            t.waitlist.other_1,
            t.waitlist.other_2,
            t.waitlist.other_3,
            t.waitlist.other_4,
        ]
    };

    console.log(issuesByUserType)


    const handleUserTypeClick = (userType) => {
        setSelectedUserType(userType);
        setSelectedProblems([]);
    };

    const toggleProblemSelection = (problem) => {
        if (selectedProblems.includes(problem)) {
            setSelectedProblems(selectedProblems.filter(item => item !== problem));
        } else {
            setSelectedProblems([...selectedProblems, problem]);
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        const newErrors = {
            userType: selectedUserType === "",
            name: name === "",
            email: !isValidEmail(email),
            privacy: !privacyChecked,
        };

        setErrors(newErrors);

        if (!newErrors.userType && !newErrors.name && !newErrors.email && !newErrors.privacy) {
            const requestData = {
                userType: selectedUserType,
                name: name,
                email: email,
                problems: selectedProblems,
            };

            try {
                setLoading(true)
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/save_waitlist_user", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                });

                if (response.ok) {
                    setTimeout(() => {
                        setLoading(false)
                        navigate("/pre-registration-confirmed")
                    }, 1000);
                } else {
                    console.error("Error saving user");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    return (
        <div className="waiting_list_container">
            <div className="waiting_list_cont first_waiting">
                <div onClick={goToHome} className='waitlist_title'>
                    <i className="fa-solid fa-layer-group"></i>
                    <p>Paypermate</p>
                </div>
                <p className="waiting_list_form_title">{t.waitlist.title}</p>
                <div className="main_img_waitlist">

                </div>
            </div>
            <div className="waiting_list_cont">
                <div className="user_type_container_waitlist">
                    <label>{t.waitlist.im_a}</label>
                    <div className="user_type_container_waitlist_cont">
                        {userTypes.map((userType, index) => (
                            <div
                                key={index}
                                className={`user_type_card_waitlist ${selectedUserType === userType.title ? 'selected_usertype' : ''}`}
                                onClick={() => handleUserTypeClick(userType.title)}
                                style={{ borderBottom: errors.userType ? '2px solid var(--danger)' : '' }}
                            >
                                <div className="img_cont_waitlist" style={{ backgroundImage: userType.bgImage }} />
                                <p className="usertype_title">{userType.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="input_label_waitlist_cont">
                    <label>{t.waitlist.name_surname}</label>
                    <input
                        className="input_waitlist"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ borderBottom: errors.name ? '2px solid var(--danger)' : '' }}
                    />
                </div>
                <div className="input_label_waitlist_cont">
                    <label>Email</label>
                    <input
                        className="input_waitlist"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ borderBottom: errors.email ? '2px solid var(--danger)' : '' }}
                    />
                    {errors.email && <p style={{ color: 'var(--danger)', fontSize: "12px", fontWeight: "bold" }}>{t.register_form.email_format}</p>}
                </div>
                {selectedUserType &&
                    <div className="input_label_waitlist_cont">
                        <label>{t.waitlist.problems}</label>
                        <div className="problematics_cont">
                            {issuesByUserType[selectedUserType].map((issue, index) => (
                                <div
                                    key={index}
                                    className={`options_problems ${selectedProblems.includes(issue) ? 'selected_problem' : ''}`}
                                    onClick={() => toggleProblemSelection(issue)}
                                >
                                    {issue}
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <div className="second_cont_reg">
                    <div className="priv_mail_cont">
                        <input
                            type="checkbox"
                            checked={privacyChecked}
                            onChange={() => setPrivacyChecked(!privacyChecked)}
                        />
                        <p
                            id="privacy_policy_desc"
                            style={{ color: errors.privacy ? 'var(--danger)' : 'inherit' }}
                        >
                            {t.register_form.privacy_policy_desc} <Link to={"/privacy-policy"}>{t.register_form.p_p}</Link>
                        </p>
                    </div>
                </div>
                <button className="waitin_list_btn_start" onClick={handleSubmit}>
                    <Spinner loading={loading} err={{message: "", display: false}} />
                    <span>{t.waiting_list}</span>
                </button>
            </div>
        </div>
    );
};

export default WaitingListForm;
